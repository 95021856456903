<template>
  <li
    v-if="canViewHorizontalNavMenuHeaderLink(item) && verifRole(item)"
    class="nav-item"
    :class="item.disable ? 'disable' : ''"
  >
    <b-link
      :title="item.title"
      class="nav-link"
      :class="item.disable ? 'not-link' : ''"
      :to="{ name: item.route }"
    >
      <span
        class="material-icons-outlined text-primary"
        v-if="item.iconType && item.iconType == 'google'"
      >
        {{ item.icon }}
      </span>
      <feather-icon size="24" :icon="item.icon" />
      <!-- <span>{{ t(item.title) }}</span> -->
    </b-link>
  </li>
</template>

<script>
import { BLink } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useUtils as useAclUtils } from "@core/libs/acl";
import useHorizontalNavMenuHeaderLink from "./useHorizontalNavMenuHeaderLink";
import mixinHorizontalNavMenuHeaderLink from "./mixinHorizontalNavMenuHeaderLink";

export default {
  components: {
    BLink,
  },
  mixins: [mixinHorizontalNavMenuHeaderLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("login"))?.login?.userInfo,
    };
  },
  setup(props) {
    const { isActive, updateIsActive } = useHorizontalNavMenuHeaderLink(
      props.item
    );

    const { t } = useI18nUtils();
    const { canViewHorizontalNavMenuHeaderLink } = useAclUtils();

    return {
      isActive,
      updateIsActive,

      // ACL
      canViewHorizontalNavMenuHeaderLink,

      // i18n
      t,
    };
  },
  methods: {
    verifRole(item) {
      if (
        item.role &&
        item.role == "admin" &&
        !this.userData.roles.includes("Admin")
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style>
.disable {
  opacity: 0.5;
}
.not-link {
  cursor: not-allowed !important;
}
</style>
